import { render, staticRenderFns } from "./ContactsTable.vue?vue&type=template&id=072846c4&scoped=true&"
import script from "./ContactsTable.vue?vue&type=script&lang=js&"
export * from "./ContactsTable.vue?vue&type=script&lang=js&"
import style0 from "./ContactsTable.vue?vue&type=style&index=0&id=072846c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072846c4",
  null
  
)

export default component.exports